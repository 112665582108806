<template>
  <div
    class="flex justify-center"
    :class="{ 'c-bitts-empty-state-large-border': size === 'large' }"
  >
    <div :class="`c-bitts-empty-state-${size}`">
      <slot name="image">
        <div class="c-bitts-empty-state__illustration">
          <BittsSvg v-if="svgName" :svg="svgName" />
          <img
            v-if="pngName"
            :src="buildImagePath(pngName)"
            :style="pngWidth ? `width:${pngWidth}` : null"
          />
          <FontAwesomeIcon
            v-if="fontAwesomeIcon"
            class="c-bitts-empty-state__fa-icon"
            :icon="fontAwesomeIcon"
            :style="fontAwesomeIconStyle"
            :class="fontAwesomeIconClass"
          />
        </div>
      </slot>
      <div class="c-bitts-empty-state__content">
        <span class="c-bitts-empty-state-title">
          {{ title }}
        </span>
        <slot name="subtitle">
          <div class="c-bitts-empty-state__subtitle-slot">
            <slot name="description">
              <div class="text-neutral-text">
                {{ description }}
              </div>
            </slot>
            <BittsLink
              v-if="link"
              v-bind="link"
              class="mt-4"
              :open-in-new-tab="link.openInNewTab || true"
            />
          </div>
          <BittsButton
            v-if="button"
            :class="button.class"
            :disabled="button.disabled || false"
            :loading="button.loading || false"
            :size="btnSize"
            :text="button.text"
            :type="button.type || 'primary'"
            :variant="button.variant || 'fill'"
            data-testid="c-bitts-empty-state-button"
            @click="emit('button-clicked')"
          />
        </slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import BittsButton, {
  BittsButtonSize,
  BittsButtonType,
  BittsButtonVariant,
} from '../BittsButton/BittsButton.vue';
import BittsLink, { BittsLinkProps } from '../BittsLink/BittsLink.vue';
import BittsSvg from '../BittsSvg/BittsSvg.vue';
import { BittsIcon, BittsSize } from '../types';

export type BittsEmptyStateSize = Exclude<
  BittsSize,
  'x-small' | 'x-large' | 'tiny'
>;
const MAP_SIZE_TO_CLASS: { [key in BittsEmptyStateSize]: string } = {
  small: '72px',
  medium: '104px',
  large: '240px',
};

type ButtonProp = {
  class?: string;
  disabled?: boolean;
  loading?: boolean;
  text?: string;
  variant?: BittsButtonVariant;
  type?: BittsButtonType;
  size?: BittsButtonSize;
};

export interface BittsEmptyStateProps {
  size?: BittsEmptyStateSize;
  button?: ButtonProp;
  fontAwesomeIcon?: BittsIcon;
  fontAwesomeIconClass?: string;
  /** Can be used to set the size of the font awesome icon. */
  fontAwesomeIconStyle?: object;
  link?: BittsLinkProps;
  pngName?: string;
  pngWidth?: string;
  description?: string;
  title?: string;
  svgName?: string;
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<BittsEmptyStateProps>(), {
  size: 'large',
  button: undefined,
  fontAwesomeIcon: undefined,
  fontAwesomeIconClass: undefined,
  fontAwesomeIconStyle: () => ({ height: '64px', width: '64px' }),
  link: undefined,
  pngName: undefined,
  pngWidth: undefined,
  description: undefined,
  title: undefined,
  svgName: undefined,
});

const emit = defineEmits(['button-clicked']);

const btnSize = computed(() => {
  if (props.button?.size) return props.button.size;
  return props.size === 'large' ? 'medium' : 'small';
});
const imgSize = computed(() => {
  return props.pngWidth ? props.pngWidth : MAP_SIZE_TO_CLASS[props.size];
});

function buildImagePath(pngName: string) {
  return new URL(`../assets/pngs/${pngName}`, import.meta.url).href;
}
</script>
<style lang="pcss" scoped>
.c-bitts-empty-state__illustration img {
  width: v-bind('imgSize');
}

.c-bitts-empty-state-large-border {
  @apply border-solid border-neutral-border border rounded-2xl bg-white max-h-full w-full;
}
.c-bitts-empty-state-large {
  @apply flex flex-col justify-center items-center p-40 w-[640px];
  .c-bitts-empty-state__content {
    @apply flex flex-col items-center mt-24 text-center;

    .c-bitts-empty-state-title {
      @apply text-lg text-neutral-text-strong pb-8 font-bold;
    }
    .c-bitts-empty-state__subtitle-slot {
      @apply mb-24;
    }
  }
}

.c-bitts-empty-state-medium {
  @apply flex items-start p-40 bg-white border-solid border-neutral-border border rounded-2xl;
  .c-bitts-empty-state__illustration {
    @apply mr-24;
  }
  .c-bitts-empty-state__content {
    .c-bitts-empty-state-title {
      @apply text-neutral-text-strong font-bold;
    }
    .c-bitts-empty-state__subtitle-slot {
      @apply flex flex-col mb-16 mt-4;
    }
  }
}

.c-bitts-empty-state-small {
  @apply flex items-center p-16 bg-white border-solid border-neutral-border border rounded-2xl w-full;
  .c-bitts-empty-state__illustration {
    @apply mr-24;
  }
  .c-bitts-empty-state__content {
    .c-bitts-empty-state-title {
      @apply text-neutral-text-strong font-bold;
    }
    .c-bitts-empty-state__subtitle-slot {
      @apply flex flex-col mb-8 mt-4;
    }
  }
}

.c-bitts-empty-state__fa-icon {
  @apply text-neutral-accent;
}
</style>
